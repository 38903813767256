import React from 'react';
import './message-list.scss';

interface IMessageList {
  messages: IMessage[];
  link: string | null;
}

export interface IMessage {
  sentBy: 'PERSON' | 'BOT';
  createdAt: Date;
  message: string;
}

export const MessageList: React.FC<IMessageList> = ({ messages, link }) => {
  let scrollContainer = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    scrollContainer.current &&
      scrollContainer.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <>
      <ul className="messageList">
        {messages.map((val, index) => (
          <li className={`message is-${val.sentBy}`} key={index} dangerouslySetInnerHTML={{__html: val.message}}>
            
          </li>
        ))}
        {link ? (
          <li className="message is-BOT">
            <a href={link} target="_blank">
              Download PDF
            </a>
          </li>
        ) : null}
      </ul>
      <div className="messageList-spacer" ref={scrollContainer} />
    </>
  );
};
