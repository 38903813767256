import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { DAClient } from '../lib/DAClient';
import { IMessage } from '../components/MessageList';
import { DATA_API_URL } from '../config';

const da = new DAClient();

interface IMessageState {
  data: IMessage[];
  lastBotIndex: number | null;
  rawUserResponse: string | null;
  fetchNextQuestion: boolean;
  link: string | null;
  searchParamSet: boolean;
  searchContext: string;
  searchResponse: any;
  sendResourceId: boolean;
}

interface IMsgUpdate {
  messageUpdated : any;
  bufferLen:any;
}

export interface IRenderProps {
  messages: IMessageState;
  setMessages: Dispatch<SetStateAction<IMessageState>>;
  resetSession: () => any;
  msgUpdated: IMsgUpdate;
  setMessageUpdated: Dispatch<SetStateAction<IMsgUpdate>>;
}

interface IMessageProps {
  render: (props: IRenderProps) => any;
}

function toArrayBuffer(myBuf: any) {
  var myBuffer = new ArrayBuffer(myBuf.length);
  var res = new Uint8Array(myBuffer);
  for (var i = 0; i < myBuf.length; ++i) {
    res[i] = myBuf[i];
  }
  return myBuffer;
}

function keySelect(phrase: any) {
  let testable = phrase.split('option ');
  testable = testable.length === 2 ? testable[1].trim() : testable[0].trim();

  const validResponses:any = {
    1: ['1', 'one', 'won'],
    2: ['2', 'two', 'too', 'to'],
    3: ['3', 'three', 'tree'],
    4: ['4', 'four', 'for', 'fore'],
    5: ['5', 'five'],
    6: ['6', 'six'],
    7: ['7', 'seven'],
    8: ['8', 'eight', 'ate', 'ape'],
    9: ['9', 'nine'],
    10: ['10', 'ten'],
    11: ['11', 'eleven'],
    12: ['12', 'twelve'],
    13: ['13', 'thirteen'],
    // prettier-ignore
    14: ['14', 'fourteen', 'four teen', 'forteen', 'for teen', 'foreteen', 'fore teen'],
    15: ['15', 'fifteen', 'fifth teen'],
    16: ['16', 'sixteen', 'six teen'],
    17: ['17', 'seventeen', 'seven teen'],
    18: ['18', 'eighteen', 'eight teen', 'ateteen', 'ate teen'],
    19: ['19', 'nineteen', 'nine teen'],
    20: ['20', 'twenty'],
    21: ['21', 'twentyone', 'twenty one'],
    // prettier-ignore
    22: ['22','twentytwo','twenty two','twentytoo','twenty too','twentyto','twenty to'],
    23: ['23', 'twentythree', 'twenty three', 'twentytree', 'twenty tree'],
    // prettier-ignore
    24: ['24','twentyfour','twenty four','twentyfor','twenty for','twentyfore','twenty fore'],
    25: ['25', 'twentyfive', 'twenty five'],
  };

  const keyLocation = Object.keys(validResponses).map((key:any) => {
    return validResponses[key].indexOf(testable) > -1 ? key : null;
  });

  return parseInt(keyLocation.filter((v:any) => v)[0]);
};


export const MessageContainer: React.FC<IMessageProps> = ({ render }) => {

  const auth = React.useContext(AuthContext);
  const accessToken = auth.jwtToken;
  const auth_username = auth.username;

  const [messages, setMessages] = useState<IMessageState>({
    data: [],
    lastBotIndex: null,
    rawUserResponse: null,
    fetchNextQuestion: true,
    link: null,
    searchParamSet: false,
    searchContext: '',
    searchResponse: [],
    sendResourceId: false
  });

  const [msgUpdated, setMessageUpdated] = useState<IMsgUpdate>({
    messageUpdated: false,
    bufferLen: 0
  });


  useEffect(() => {
    const fetchAndPlayAudio = async (lastMessage: IMessage) => {
      var filterMessage = ((lastMessage.message).toString().replace(/<br\/>/g," "));
      filterMessage = filterMessage.replace('&nbsp;',"");
      try {
        const res = await fetch(DATA_API_URL+'/v1/audio/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ text: filterMessage }),
        });

        const data  = await res.json();
        localStorage.setItem('muteBot', 'false');
        const buffer = toArrayBuffer(data.audio.data);

        const audioCtx = new ((window as any).AudioContext ||
          (window as any).webkitAudioContext)();
        const source = audioCtx.createBufferSource();

        audioCtx.decodeAudioData(
          buffer,
          function(buffer: any) {
            source.buffer = buffer;
            var scriptNode = audioCtx.createScriptProcessor(4096, 1, 1);
            source.connect(audioCtx.destination);
            scriptNode.connect(audioCtx.destination);
            source.start();
            // Give the node a function to process audio events
            scriptNode.onaudioprocess = function (audioProcessingEvent) {
              // The input buffer is the song we loaded earlier
              var inputBuffer = audioProcessingEvent.inputBuffer;

              // The output buffer contains the samples that will be modified and played
              var outputBuffer = audioProcessingEvent.outputBuffer;

              // Loop through the output channels (in this case there is only one)
              for (var channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                if (localStorage.getItem('muteBot') == "true") {
                  // gainNode.gain.value = 0.0;
                  source.stop();
                } else {
                  // gainNode.gain.value = 0.5;
                }
                var inputData = inputBuffer.getChannelData(channel);
                var outputData = outputBuffer.getChannelData(channel);

                // Loop through the 4096 samples
                for (var sample = 0; sample < inputBuffer.length; sample++) {
                  // make output equal to the same as the input
                  outputData[sample] = inputData[sample];
                }
              }
            }

          },
          function(e: any) {
          },
        );

        source.onended = function() {
          if(localStorage.getItem('muteBot') != "true"){
            setMessageUpdated({
              ...msgUpdated,
              messageUpdated: true,
              bufferLen: data.audio.data.length
            });
          }
        }
      } catch (error) {
      }
    };

    const updateAndFetchNext = async (lastMessage: IMessage) => {
      await updateVariableInDocassemble(lastMessage);
      setMessages({ ...messages, fetchNextQuestion: true });
    };

    const updateVariableInDocassemble = async (lastMessage: IMessage) => {
      try {
        if (messages.lastBotIndex === null) {
          throw Error('No Bot Message Index');
        }

        // const botMessage = messages.data[messages.lastBotIndex];
      } catch (error) {
      }
    };

    if (messages.data.length > 0) {
      const lastMessage = messages.data[messages.data.length - 1];

      if (lastMessage.sentBy === 'BOT') {
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: false
        });
        setMessages({
          ...messages,
          fetchNextQuestion: false,
          lastBotIndex: messages.data.length - 1,
        });

        /** Message by BOT is added */
        fetchAndPlayAudio(lastMessage);
      } else {
        /** Message by PERSON is added */
        updateAndFetchNext(lastMessage);
        // fetchAndPlayAudio(lastMessage);
        /**
         * 1. update variable in docassemble
         * 2. fetch next question
         */
      }
    }
  }, [messages.data]);


  useEffect(() => {
    if (msgUpdated.messageUpdated) {
      setMessageUpdated({
        ...msgUpdated,
        messageUpdated: false
      });
    }
  }, [msgUpdated.messageUpdated]);


  useEffect(() => {
    if (messages.rawUserResponse) {
      const message: IMessage = {
        message: messages.rawUserResponse,
        sentBy: 'PERSON',
        createdAt: new Date()
      };

      const data = [...messages.data, message];

      setMessageUpdated({
        ...msgUpdated,
        messageUpdated: false
      });

      setMessages({ ...messages, rawUserResponse: null, data });

    }
  }, [messages.rawUserResponse]);

  useEffect(() => {
    const fetchNextQuestion = async () => {
      try {
        const sessionId = auth_username;
        let msg = 'hello';
        let postBody = {};
        let fetchUrl = DATA_API_URL+'/v1/conversations/' + sessionId;
        let chatBotMessage = '';

        if (messages.data.length > 0) {
          msg = messages.data[messages.data.length - 1].message;
        }

        // const botMessage = messages.data[messages.lastBotIndex];

        if (messages.sendResourceId) {
          msg = msg.toLowerCase().replace(/mission/gi, 'option');
          const keySelection = keySelect(msg);
          const item = messages.searchResponse[keySelection - 1] || false;

          if (item) {
            msg = item.id;
            messages.sendResourceId = false;
          }
        }
        if (messages.sendResourceId) {
          chatBotMessage = "I couldn't understand the option you said, please select again.<br/><br/>";
          messages.searchResponse.map((item:any , i:any) => {
            if (messages.searchContext) {
              const name =
                messages.searchContext === 'matter'
                  ? item.display_number
                  : item.name;
              chatBotMessage = `${chatBotMessage} Option ${i + 1}) ${name}<br/>`;
            }
          });
          chatBotMessage = chatBotMessage + "<br/>Please say 'Option X' from the list above.";
        } else {
          if (messages.searchParamSet) {
            fetchUrl = DATA_API_URL+'/v1/search';
            postBody = {
              resource: messages.searchContext,
              query: msg,
            };
            messages.searchParamSet = false;
          } else {
            postBody = {
              text: msg,
              response: {
                audio: true,
                text: true,
              },
            };
          }

          const sendMsg = await fetch(
            fetchUrl,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(postBody),
            },
          );

          const res = await sendMsg.json();


          if(res.message != undefined) {
            chatBotMessage = res.message;
          } else if(res.data != undefined && res.data.length > 0) {
            res.data.map((item: any, i:any) => {
              const name =
                messages.searchContext === 'matter'
                  ? item.display_number
                  : item.name;

              chatBotMessage = `${chatBotMessage}Option ${i + 1}) ${name} <br/>`;
            });
            chatBotMessage = chatBotMessage + "<br/>Please say 'Option X' from the list above.";

            messages.searchResponse = res.data;
            messages.sendResourceId = true;

          } else if (res.data != undefined && res.data.length == 0) {
            chatBotMessage =
              'I could not find that ' + messages.searchContext + ', please try again';

            messages.searchParamSet = true;
          }
          if (res.search != undefined) {
            messages.searchParamSet = true;
            messages.searchContext = res.search;
          }
        }


        const message: IMessage = {
          message: chatBotMessage,
          sentBy: 'BOT',
          createdAt: new Date()
        };

        const data = [...messages.data, message];
        setMessages({ ...messages, data});

      } catch (error) {
      }
    };

    if (messages.fetchNextQuestion) {
      fetchNextQuestion();
    }
  }, [messages.fetchNextQuestion, accessToken, auth_username]);

  const resetSession = async () => {
    await da.newSession();
    await setMessages({
      data: [],
      lastBotIndex: null,
      rawUserResponse: null,
      fetchNextQuestion: true,
      link: null,
      searchParamSet: false,
      searchContext: '',
      searchResponse: [],
      sendResourceId: false
    });
  };

  return render({ messages, resetSession, setMessages, msgUpdated, setMessageUpdated });
};
