import React from 'react';
import Icon from '@material-ui/core/Icon';
import { AuthContext } from '../../contexts/AuthContext';

import './mic.scss';

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

interface Props {
  onResults: (userResponse: string, msgUpdated: boolean) => void;
  onMicChange: (status: boolean) => void;
  onBotResponse: any;
  botMsgLen: any;
}

export const Mic: React.FC<Props> = ({ onResults, onMicChange, onBotResponse, botMsgLen }) => {

  const auth = React.useContext(AuthContext);
  const [hasBrowserSupport, setHasBrowserSupport] = React.useState<Boolean>(
    false,
  );
  let recognition: any;
  let soundDetect: any = false;
  let soundStart: any = false;
  let soundError: any = false;

  const { webkitSpeechRecognition }: IWindow = window as IWindow;
  if (webkitSpeechRecognition) {
    !hasBrowserSupport && setHasBrowserSupport(true);

    recognition = new webkitSpeechRecognition();
    // const speechRecognitionList = new SpeechGrammarList();
    // speechRecognitionList.addFromString(grammar, 1);
    // recognition.grammars = speechRecognitionList;
    // recognition.continuous = false;

    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    setTimeout(() => { 
      if(onBotResponse && !soundStart && auth.user['attributes']['custom:clioAccessToken']) {
        // document.getElementById('startMic').click();
        micStart();
      }
    }, 750);


    recognition.onstart = function() {
      // console.log('speech start');
      soundStart = true;
    }

    recognition.onend = function() {
      // console.log('speech end');
      micStop();
    }

    recognition.onsoundstart = function() {
      // console.log('speech sound start');
    }

    recognition.onsoundend = function() {
      // console.log('speech sound end');
    }
    
    recognition.onspeechstart = function() {
      // console.log('speech start');
      soundDetect = true;
      // console.log(soundDetect);
    }

    recognition.onspeechend = function() {
      // console.log('speech end');
      onBotResponse = false;
    }

    recognition.momatch = function() {
      // console.log('speech no match');
    }

    recognition.onaudiostart = function() {
      // console.log('speech audio start');
      setTimeout(() => {
        // console.log(soundDetect);  
        if(!soundDetect) {
          recognition.onerror({error:'no-speech'});
        }
      }, 3000);
    }

    recognition.onaudioend = function() {
      // console.log('speech audio end');
    }

    recognition.onerror = function(event) {
      // console.log('speech error'+event.error);
      if(event.error === "no-speech") {
          // onResults(' ', false);
          soundError = true;
          micStop();
      }
    }

    recognition.onresult = (event: any) => {
      // console.log('Recognition.onResult', event.results[ event.results.length - 1][0].transcript);
      // The SpeechRecognitionEvent results property returns a SpeechRecognitionResultList object
      // The SpeechRecognitionResultList object contains SpeechRecognitionResult objects.
      // It has a getter so it can be accessed like an array
      // The [last] returns the SpeechRecognitionResult at the last position.
      // Each SpeechRecognitionResult object contains SpeechRecognitionAlternative objects that contain individual results.
      // These also have getters so they can be accessed like arrays.
      // The [0] returns the SpeechRecognitionAlternative at position 0.
      // We then return the transcript property of the SpeechRecognitionAlternative object

      const last = event.results.length - 1;
      const text = event.results[last][0].transcript;
      onResults(text, false);

      // console.log('Confidence: ' + event.results[0][0].confidence);
    };
  }

  const micStart = async() => {
    onMicChange(true);
    recognition.start();
    document.getElementById('autoStartMic').classList.add("hidden");
    document.getElementById('stopMic').classList.remove("hidden");
    document.getElementById('startMic').classList.add("hidden");
  }

  const micStop = async() => {
    onMicChange(false);
    recognition.stop();
    document.getElementById('stopMic').classList.add("hidden");

    if(soundError) {
      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('startMic').classList.remove("hidden");
    } else {
      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('startMic').classList.remove("hidden");
    }
  }

  if (hasBrowserSupport) {
    return (
      <>
        <Icon id="autoStartMic" className="mic-icon">
          mic
        </Icon>

        <Icon onClick={() => micStart()} id="startMic" className="mic-icon hidden">
          mic
        </Icon>

        <Icon onClick={() => micStop()} id="stopMic" className="stop-icon hidden">
          clear
        </Icon>

        {/* <button className="mic" onClick={}>
          Click To Speak
        </button> */}
        {/* <button className="mic" onClick={() => onResults('Jeremy')}>
          Respond with 'Jeremy'
        </button> */}
      </>
    );
  }

  return (
    <p>
      Your Browser Doesn't Support SpeechRecognition. Try using the latest
      version of Google Chrome.
    </p>
  );
};
