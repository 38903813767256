import * as React from 'react';
import './header.scss';
import { Button } from '../Button';
import { Container } from '../Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AuthContext } from '../../contexts/AuthContext';
import Modal from 'react-bootstrap/Modal';
import CryptoJS from 'crypto-js';
import { BACKEND_API_URL, API_URL, CLIO_CLIENT_ID, CRYPTO_KEY, DATA_API_URL } from '../../config';

export const Header: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const accessToken = auth.jwtToken;
  let showVal: any = false;

  if (auth.user['attributes']['custom:clioAccessToken'] && !auth.user['attributes']['custom:formCompleted']) {
    showVal = false;
  }

  if (auth.user['attributes']['custom:formCompleted'] && !auth.user['attributes']['custom:clioAccessToken']) {
    showVal = true;
  }

  const encryptText = (text: string) => {
    return CryptoJS.AES.encrypt(text, CRYPTO_KEY);
  };

  const decryptText = (text: string) => {
    return CryptoJS.AES.decrypt(text, CRYPTO_KEY);
  };

  const clioAuthorize = async () => {
    try {
      window.location.href = "https://app.clio.com/oauth/authorize?response_type=code&client_id=" + CLIO_CLIENT_ID + "&redirect_uri=" + API_URL;
    } catch (error) {
    }
  };

  const logout = async () => {
    try {
      auth.logout && auth.logout();
    } catch (error) {
    }
  }

  const handleClio = async () => {
    try {
      let query_params = new URLSearchParams(window.location.search);
      let clioCode = query_params.get('code');

      if (clioCode && clioCode != null && !auth.user['attributes']['custom:clioAccessToken'] && window.location.pathname != '/openApp') {
        fetch(BACKEND_API_URL + 'clioAuthorize?code=' + clioCode + '&reqFrom=Lawdroid')
          .then(response => response.json())
          .then(res => {
            if (!res['error']) {
              let clioId = (res['userData']['id']).toString();
              let clioAccessToken = encryptText(res['access_token']).toString();
              let clioRefreshToken = encryptText(res['refresh_token']).toString();
              let clioTimestamp = '' + res['timestamp'];
              // let clioLastUpdatedAt = ''+Date.now();

              auth.updateUserClio && (auth.updateUserClio({ clioId, clioAccessToken, clioRefreshToken, clioTimestamp }));
              handleClose();
            } else {
              // window.location.href = '/';
            }
          });
      } else {
        await fetch(
          DATA_API_URL + '/v1/search',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              query: 'matter 00001',
              resource: 'matter'
            }),
          },
        ).then(r => {
          if (r.status !== 200) {
            clioDeauthorize();
          }
        }).catch(err => {
          clioDeauthorize();
        });
      }
    } catch (error) {
    }
  };

  const clioDeauthorize = async () => {
    localStorage.setItem('muteBot', 'true');
    try {

      fetch(BACKEND_API_URL + 'clioDeauthorize', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: auth.user['attributes']['custom:clioAccessToken'] })
      })
        .then(res => res.json())
        .then(response => {
          if (response['msg'] === "Successful Response") {
            let clioId = '';
            let clioAccessToken = '';
            let clioRefreshToken = '';
            let clioTimestamp = '';

            auth.updateUserClio && (auth.updateUserClio({ clioId, clioAccessToken, clioRefreshToken, clioTimestamp }));
            handleShow();
          } else {
          }
        });
    } catch (error) {
    }
  };

  const [show, setShow] = React.useState(showVal);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  const [vol, setVol] = React.useState(true);
  const handleMute = () => {
    setVol(false);
  }
  const handleVol = () => {
    setVol(true);
  }

  React.useEffect(() => {
    handleClio();
  }, [auth]);

  const clioButtonsRender = () => {
    if (!auth.user['attributes']['custom:clioAccessToken']) {
      return (<Nav.Link href="#" onClick={() => handleShow()} className="btn btn-primary px-3 mr-2 text-white clioAuth"><i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">swap_horizontal_circle</i> Authorize Clio</Nav.Link>);

    } else {
      return (<Nav.Link href="#" onClick={() => clioDeauthorize()} className="btn btn-primary mr-2 px-3 text-white"><i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">swap_horizontal_circle</i> Deauthorize Clio</Nav.Link>);
    }
  }

  function handleClick(e) {
    e.preventDefault();
    var element = document.getElementById('toggler_custom');
    element.classList.add("show");
    var element = document.getElementById('overlay');
    element.classList.add("active");
  }

  function overlayClick(e) {
    e.preventDefault();
    document.getElementById("toggler_custom").click();
    var element = document.getElementById('overlay');
    element.classList.remove("active");
  }

  const volumeImageRender = () => {
    if (vol) {
      return (<img className="d-block d-sm-none vol" onClick={() => handleMute()} src={require('../../assets/images/volume.png')} alt="volume" />);
    } else {
      return (<img className="d-block d-sm-none vol" onClick={() => handleVol()} src={require('../../assets/images/no-volume.png')} alt="mute" />);
    }
  }

  return (
    <header className="header">
      <meta property="og:title" content="LawDroid Voice | AI Voice Assistant for Lawyers" />
      <meta property="og:description" content="LawDroid Voice is the world's first AI voice assistant tailored for the needs of the legal industry. It helps you get more done in less time by letting you use voice commands to control your software." />
      <meta property="og:image" content="./images/LawDroid_Voice_Logo.png" />
      <meta name="keywords" content="AI, lawyers, voice assistant, attorneys, legal tech, artificial intelligence, Siri, Alexa, voice control, voice commands, solicitors, barristers, voice automation" />

      <title>LawDroid Voice | AI Voice Assistant for Lawyers</title>

      <Container>
        <div className="navigation">
          <Navbar collapseOnSelect expand="lg" bg="white" className="px-0">
            <Navbar.Brand href="/" className="mr-auto">
              <img className="d-none d-sm-block" src={require('../../assets/images/LawDroid_Voice_Logo.png')} alt="Lawdroid Logo" />
              <img className="d-block d-sm-none sm-logo" src={require('../../assets/images/Logo.png')} alt="Lawdroid Logo" />
            </Navbar.Brand>
            {volumeImageRender()}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="p-0 border-0 font-weight-bold" id="toggler_custom" onClick={handleClick} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
              </Nav>
              <Nav className="">
                <div className="row base_color mb-3 py-3 d-block d-lg-none">
                  <div className="col-12 text-white">
                    <h5 className="text-white">{auth.user['attributes']['custom:firstName']}  {auth.user['attributes']['custom:lastName']}</h5>
                    <div>{auth.user['attributes']['email']}</div>
                  </div>
                </div>
                <Nav.Link href="/profile" className="btn btn-primary px-3 mr-lg-2 mr-sm-0 mr-0 text-white"><i _ngcontent-ogf-c19="" className="d-block d-lg-none material-icons icon-image-preview">account_circle</i> Profile</Nav.Link>

                {clioButtonsRender()}

                <Nav.Link href="/timeline" className="btn btn-primary px-3 mr-lg-2 mr-sm-0 mr-0 text-white"><i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">calendar_today</i> Show Timeline</Nav.Link>
                <Nav.Link href="/commands" className="btn btn-primary px-3 mr-lg-2 mr-sm-0 mr-0 text-white"><i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">last_page</i> Commands</Nav.Link>
                <Nav.Link href="/terms" className="d-block d-lg-none btn btn-primary px-3 mr-lg-2 mr-sm-0 mr-0 text-white">
                  <div className="d-flex align-items-center w-100">
                    <i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">file_copy</i> Terms & Conditions
                    </div>
                </Nav.Link>
                <Nav.Link href="/privacy" className="d-block d-lg-none btn btn-primary px-3 mr-lg-2 mr-sm-0 mr-0 text-white">
                  <div className="d-flex align-items-center w-100">
                    <i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">lock</i> Privacy Policy
                    </div>
                </Nav.Link>
                <Nav.Link href="#" onClick={() => logout()} className="btn btn-primary px-3 text-white"><i _ngcontent-jlq-c19="" className="d-block d-lg-none material-icons icon-image-preview">exit_to_app</i>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="overlay" id="overlay" onClick={overlayClick}></div>
        </div>
        <div className="row border-2 p-0">
          <div className="col edit">
            <Modal show={show}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5>You need to connect Clio to work with LawDroid voice.</h5>
                  <Button className="btn btn-primary px-3 text-white" onClick={clioAuthorize}>
                    CONNECT TO CLIO
                    </Button>
                </Modal.Title>
              </Modal.Header>

            </Modal>
          </div>
        </div>
      </Container>
    </header>
  );
};
